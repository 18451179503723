import { BaseApiRouteService } from "mrb/core";

class PodcastApiRouteService extends BaseApiRouteService {
    constructor() {
        super("podcasts");
    }

    find(filter) {
        return super.find(`${this.base}/{?searchQuery,page,rpp,sort,embed,fields}`, filter);
    }

    findEpisodes(podcastId, filter) {
        return super.find(`${this.base}/{id}/episodes{?searchQuery,page,rpp,sort,embed,fields}`, {
            id: podcastId,
            ...filter,
        });
    }

    getAllEpisodes(id, options) {
        return super.get(`${this.base}/{id}/episodes/all{?embed,fields}`, id, options);
    }

    findShufflablePodcast(filter) {
        return super.create(`${this.base}/shufflable-podcast`, filter);
    }

    findDevicePodcasts(filter) {
        return super.find(`${this.base}/device-podcasts/{?deviceId,searchQuery,page,rpp,sort}`, filter);
    }

    get(id, options) {
        return super.get(`${this.base}/{id}{?embed,fields}`, id, options);
    }

    create(resource) {
        return super.create(this.base, resource);
    }

    createGroup(resource) {
        return super.create(`${this.base}/group`, resource);
    }

    update(resource) {
        return super.update(`${this.base}/{id}`, resource);
    }

    updateGroup(resource) {
        return super.update(`${this.base}/{id}/group`, resource);
    }

    delete(resource) {
        return super.delete(`${this.base}/{id}`, resource);
    }

    updateActiveStatus(id, value) {
        return super.update(`${this.base}/{id}/active{?value}`, {
            id: id,
            value: value,
        });
    }

    updatePodcastTurnedOnStatus(deviceId, podcastId, value) {
        return super.update(`${this.base}/{podcastId}/device/{deviceId}/turned-on{?value}`, {
            deviceId: deviceId,
            podcastId: podcastId,
            value: value,
        });
    }

    updateOnByDefaultStatus(id, value) {
        return super.update(`${this.base}/{id}/on-by-default{?value}`, {
            id: id,
            value: value,
        });
    }

    updateOnByDefaultInAppStatus(id, value) {
        return super.update(`${this.base}/{id}/on-by-default-in-app{?value}`, {
            id: id,
            value: value,
        });
    }

    updatePremiumStatus(id, value) {
        return super.update(`${this.base}/{id}/premium{?value}`, {
            id: id,
            value: value,
        });
    }

    updateVisibilityStatus(id, value) {
        return super.update(`${this.base}/{id}/visibility{?value}`, {
            id: id,
            value: value,
        });
    }

    updateAppVisibilityStatus(id, value) {
        return super.update(`${this.base}/{id}/app-visibility{?value}`, {
            id: id,
            value: value,
        });
    }

    updateRecentlyUpdatedVisibilityStatus(id, value) {
        return super.update(`${this.base}/{id}/recently-updated-visibility{?value}`, {
            id: id,
            value: value,
        });
    }

    updateFeaturedStatus(id, value) {
        return super.update(`${this.base}/{id}/featured{?value}`, {
            id: id,
            value: value,
        });
    }

    updateHlsEnabledStatus(id, value) {
        return super.update(`${this.base}/{id}/hls-enabled{?value}`, {
            id: id,
            value: value,
        });
    }

    editPodcastEpisodeTitle(id, resources) {
        return super.update(`${this.base}/episode/{id}/title`, {
            resources: resources,
            id: id,
        });
    }

    uploadEpisode(podcastId) {
        return super.create(`${this.base}/{id}/episode`, { id: podcastId });
    }

    findAudioTrackEpisodes(filter) {
        return super.find(
            `${this.base}/episodes{?page,searchQuery,podcastIds,podcastGroupIds,rpp,sort,embed,fields,audioTrackAvailable}`,
            filter
        );
    }

    getPodcasts(filter) {
        return super.find(`${this.base}/{?page,searchQuery,rpp,sort,embed,fields,podcastType}`, filter);
    }
}

export default PodcastApiRouteService;
