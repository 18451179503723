import { BaseApiRouteService } from "mrb/core";

class NakiNigunAlbumApiRouteService extends BaseApiRouteService {
    constructor() {
        super("naki-nigun/albums");
    }

    get(id, options) {
        return super.get(`${this.base}/{id}{?embed,fields}`, id, options);
    }

    findSongs(albumId, filter) {
        return super.find(`${this.base}/{id}/songs{?searchQuery,page,rpp,sort,embed,fields}`, {
            id: albumId,
            ...filter,
        });
    }

    getAllSongs(id, options) {
        return super.get(`${this.base}/{id}/songs/all{?embed,fields}`, id, options);
    }

    updateActiveStatus(id, value) {
        return super.update(`${this.base}/{id}/active{?value}`, {
            id: id,
            value: value,
        });
    }

    updatePremiumStatus(id, value) {
        return super.update(`${this.base}/{id}/premium{?value}`, {
            id: id,
            value: value,
        });
    }

    updateFeaturedStatus(id, value) {
        return super.update(`${this.base}/{id}/featured{?value}`, {
            id: id,
            value: value,
        });
    }

    updateOnByDefaultStatus(id, value) {
        return super.update(`${this.base}/{id}/on-by-default{?value}`, {
            id: id,
            value: value,
        });
    }

    updateOnByDefaultInAppStatus(id, value) {
        return super.update(`${this.base}/{id}/on-by-default-in-app{?value}`, {
            id: id,
            value: value,
        });
    }

    findShufflableNakiNigunAlbum(filter) {
        return super.create(`${this.base}/shufflable-album`, filter);
    }

    updateHlsEnabledStatus(id, value) {
        return super.update(`${this.base}/{id}/hls-enabled{?value}`, {
            id: id,
            value: value,
        });
    }

    editAlbumTrackTitle(id, resources) {
        return super.update(`${this.base}/album-track/{id}/title`, {
            resources: resources,
            id: id,
        });
    }

    uploadAlbumTrack(albumId) {
        return super.create(`${this.base}/{id}/track`, { id: albumId });
    }

    findAudioTrackAlbumSongs(filter) {
        return super.find(
            `${this.base}/songs{?page,searchQuery,albumIds,artistIds,rpp,sort,embed,fields,audioTrackAvailable}`,
            filter
        );
    }

    findAlbums(filter) {
        return super.find(`${this.base}{?page,searchQuery,rpp,sort,embed,fields}`, filter);
    }
}

export default NakiNigunAlbumApiRouteService;
